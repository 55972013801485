import {
  BreadcrumbItem,
  DetailsTab,
} from '../../../../core/modals/details.modal';

export const meetingDetailsPagebreadcrumbs: BreadcrumbItem[] = [
  { label: 'Meetings', url: '/review-meeting/meetings' },
  { label: '', url: '' },
];

export const meetingDetailsPageTabs: DetailsTab[] = [
  {
    link: '/review-meeting/meetings/:id/overview',
    icon: 'overview-tab',
    label: 'Overview',
    isActive: true,
  },
  {
    link: '/review-meeting/meetings/:id/mom',
    icon: 'mom-tab',
    label: 'MOM',
    isActive: false,
  },
  {
    link: '/review-meeting/meetings/:id/associated-items',
    icon: 'associated-items-tab',
    label: 'Associated Items',
    isActive: false,
  },
  {
    link: '/review-meeting/meetings/:id/action-plans',
    icon: 'action-plans-tab',
    label: 'Action Plans',
    isActive: false,
  },
  
];
