import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { MeetingListResponseDTO, MeetingFormDTO, MeetingDetailsAssociatedItemSummaryDTO } from '../dto/meeting.dto';
import { MainBaseService } from '../../../../core/services/main-base-service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class MeetingService extends MainBaseService<MeetingListResponseDTO,MeetingFormDTO> {
  protected apiUrl = environment.apiUrl + '/meetings';

  constructor(http: HttpClient) {
    super(http);
  }

  getMeetingAssociatedItemSummary(
    meetingId: number
  ): Observable<MeetingDetailsAssociatedItemSummaryDTO> {
    return this.http.get<MeetingDetailsAssociatedItemSummaryDTO>(
      `${this.apiUrl}/${meetingId}/meeting-summary`
    );
  }
}
