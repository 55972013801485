import { Injectable } from '@angular/core';
import {
  BreadcrumbItem,
  DetailsTab,
} from '../../../../core/modals/details.modal';

import { DetailsBaseStore } from '../../../../core/store/details-base.store';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import { MeetingDetailsAssociatedItemSummaryDTO, MeetingDetailsDTO } from '../dto/meeting.dto';
import { MeetingService } from '../services/meeting.service';
import { MeetingConversion } from '../conversion/meeting.conversion';
import { meetingDetailsPagebreadcrumbs, meetingDetailsPageTabs } from '../config/meeting-details.config';

@Injectable({ providedIn: 'root' })
export class MeetingDetailsStore extends DetailsBaseStore<MeetingDetailsDTO> {
  tabs: DetailsTab[] = [];
  breadcrumbs: BreadcrumbItem[] = [];
  mainId: number;
  showDetailsModal: boolean;
  meeting: MeetingDetailsDTO;
  meetingCount: MeetingDetailsAssociatedItemSummaryDTO = {
    agenda: 0,
    mom: 0,
    action_plan: 0,
  };

  constructor(
    private meetingService: MeetingService,
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    private meetingConversion: MeetingConversion
  ) {
    super();
    makeObservable(this, {
      convertResponseToDTO: action,
    });
  }

  override initialize(): void {
    this.tabs = meetingDetailsPageTabs;
    this.breadcrumbs = meetingDetailsPagebreadcrumbs;
  }

  fetchMeetingAssociatedItemSummary(metingId: number): void {
    this.service.getMeetingAssociatedItemSummary(metingId).subscribe({
      next: (result: MeetingDetailsAssociatedItemSummaryDTO) => {
        runInAction(() => {
          this.meetingCount = result;
        });
      },
      error: (error: any) => {
        console.error('Failed to fetch details:', error);
        runInAction(() => {});
      },
    });
  }

  override get service(): any {
    return this.meetingService;
  }

  convertResponseToDTO(response: any) {
    const details = this.meetingConversion.resToDetails(response);
    this.breadcrumbs[1].label = details.title;
    return details;
  }
}
